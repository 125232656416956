import React, { useContext, useEffect, useState } from "react";
import { handleException } from "../helpers/handleException";
import { User } from "../services/User";
import { Login } from "../services/Login";
import handleApiError from "../helpers/handleApiError";

export const AuthContext = React.createContext({});

export const AuthProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const user = useProvideAuth();

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};

export const useAuth = (): any => useContext(AuthContext);

const useProvideAuth = () => {
  const [user, setUser] = useState("");
  const [self, setSelf] = useState({});
  const [following, setFollowing] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const token = localStorage.getItem("token");
        if (token) {
          setUser(token);
        }
      } catch (e) {
        handleException(null, e);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (!user) return;
        const self = await getSelf();
        setSelf(self);
      } catch (e) {
        handleApiError(e, logout);
      }
    })();
  }, [user]);

  const login = async (token: string) => {
    if (!token) return;
    localStorage.setItem("token", token);
    setUser(token);
  };

  const getSelf = async () => {
    const { data: response } = await User.getSelf(user);
    const { data } = response;
    return data;
  };

  const logout = () => {
    if (!user) return;
    localStorage.removeItem("token");
    setUser("");
  };

  return {
    user,
    login,
    self,
    logout,
    following,
    setFollowing,
  };
};
