import React from "react";
import { StreamerInterface } from "../models/StreamerInterface";
import StreamerSnippet from "./StreamerSnippet";
import "./LiveFeedCard.scss";
import Tilt from "react-parallax-tilt";

export default ({
  displayName,
  gameName,
  profileUrl,
  thumbnailUrl,
  title,
  viewers,
}: StreamerInterface) => {
  return (
    <a
      href={`https://twitch.tv/${displayName}`}
      target="_blank"
      className="text-decoration-none"
    >
      <div className="live-feed__card">
        <div className="live-feed__thumbnail">
          <img
            src={thumbnailUrl
              ?.replace("{width}", "440")
              .replace("{height}", "248")}
          />
        </div>
        <div className="live-feed__snippet px-20 pt-20 pb-20">
          <StreamerSnippet
            avatar={profileUrl?.replace("300x300", "50x50")}
            username={displayName}
            category={gameName}
            viewcount={viewers}
            title={title}
            direction="ltr"
          />
        </div>
      </div>
    </a>
  );
};
