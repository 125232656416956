import twitchIcon from "../images/twitch-icon.svg";
import { Button } from "react-bootstrap";
import React from "react";
import { Login } from "../services/Login";

export default () => {
  const handleLoginRequest = async () => {
    const { data: response } = await Login.request(
      process.env.REACT_APP_BASE_URL
    );
    const { data } = response;
    if (!data) return;
    window.location.href = data.url;
  };

  return (
    <Button
      variant="twitch"
      className="d-flex w-100 position-relative justify-content-center align-items-center"
      onClick={handleLoginRequest}
    >
      <img src={twitchIcon} className="ms-2" />
      ورود با توییچ
    </Button>
  );
};
