import { notification } from "./notification";
import errorParser from "./errorParser";

export const handleException = (
  title: string | null = "Something went wrong.",
  error: any
) => {
  const [apiError] = error?.response?.data?.errors || [];
  error = error.toString();
  error = errorParser(apiError)
    ? errorParser(apiError)
    : error === "TypeError: Failed to fetch"
    ? "Something went wrong"
    : error;
  notification("error", title || "", error);
  console.error(error);
};
