export default (n: number) => {
  const farsiDigits = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];

  return (
    n
      .toString()
      // @ts-ignore
      .replace(/\d/g, (x) => farsiDigits[x])
  );
};
