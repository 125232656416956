import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import hero from "../images/full-features.svg";
import toPersianNum from "../helpers/toPersianNum";
import LoginWithTwitch from "./LoginWithTwitch";
import { useAuth } from "../hooks/useAuth";
import { useHistory } from "react-router-dom";

export default () => {
  const { user } = useAuth();
  const history = useHistory();
  useEffect(() => {
    if (user) {
      window.location.replace("/");
    }
  }, [user]);

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={true}
      backdrop={true}
      className="modal text-center"
      onHide={() => history.replace("/home")}
    >
      <Modal.Body className="py-60 px-50">
        <img src={hero} alt="افزودن استریمر" />
        <h4 className="mt-30">امکانات کامل سایت</h4>
        <div className="modal-form text-secondary mt-10">
          <p>برای دیدن محتوای این قسمت لازم است تا در توییچ‌فا وارد شوید.</p>
        </div>
        <LoginWithTwitch />
      </Modal.Body>
    </Modal>
  );
};
