import React, { useEffect, useState } from "react";
import "./Banner.scss";
import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Banner } from "../services/Banner";

export default ({
  imageUrl,
  className,
  height,
}: {
  imageUrl: string;
  className: string;
  height: number;
}) => {
  const [featured, setFeatured] = useState<any>();

  useEffect(() => {
    (async () => {
      const { data: response } = await Banner.getAll();
      if (!response) return;
      const { data } = response;
      if (!data) return;
      const [featuredBanner] = data;
      setFeatured(featuredBanner);
    })();
  }, []);

  return (
    <>
      {featured && (
        <div
          className={`banner`}
          style={{ backgroundImage: `url(${imageUrl})`, height }}
        >
          <Container>
            <div className="banner__container d-flex flex-column justify-content-center px-20 px-md-40 px-xxl-30">
              <div className="row align-items-end">
                <div className="col">
                  <h2 className="banner__title d-flex mb-10">
                    {featured?.title}
                  </h2>
                  {featured?.details?.liveData?.gameName && (
                    <div className="banner__category mb-15">
                      <span className="d-inline-block px-2">
                        {featured?.details?.liveData?.gameName}
                      </span>
                    </div>
                  )}

                  <div className="banner__details row align-items-center g-3">
                    <div className="col-auto">
                      <div className="banner__details__profile rounded py-1 d-flex align-items-center">
                        {featured?.details?.profileUrl && (
                          <div
                            className="p-1 border rounded-circle ms-2"
                            style={{
                              borderColor: "#717dfe",
                              borderWidth: "2px",
                            }}
                          >
                            <img
                              src={featured?.details?.profileUrl}
                              width={22}
                              height={22}
                              className="rounded"
                            />
                          </div>
                        )}
                        {featured?.details?.displayName}
                      </div>
                    </div>
                    <div className="col-auto d-none d-sm-block">
                      {featured?.details?.liveData?.viewers && (
                        <div className="banner__details_viewers-count">
                          ویوئر:
                          <span className="d-inline-block px-2">
                            {featured?.details?.liveData?.viewers}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-auto d-none d-xs-block">
                  <div className="d-flex flex-column flex-md-row align-items-center pt-xl-20">
                    <div>
                      {featured && featured?.details?.liveData && (
                        <div className="d-flex align-items-center banner__details__live-indicator font-weight-bold">
                          <div className="live-indicator">
                            <div className="live-pulse-min"></div>
                            <div className="live-pulse-max"></div>
                            <span className="live-indicator__circle"></span>
                          </div>
                          <span className="d-inline-block ps-md-20">زنده</span>
                        </div>
                      )}
                    </div>
                    <div>
                      {featured?.details?.displayName && (
                        <div className="banner__call-to-action">
                          <a
                            href={`https://twitch.tv/${featured?.details?.displayName}`}
                            target="_blank"
                          >
                            <Button variant="primary" size="sm">
                              مشاهده کنید
                            </Button>
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      )}
    </>
  );
};
