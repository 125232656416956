import React from "react";
import { Dropdown, Form } from "react-bootstrap";
import searchIcon from "../images/search.svg";
import "./Header.scss";
import { useAuth } from "../hooks/useAuth";
import { useSearch } from "../hooks/useSearch";
import Notifications from "../components/Notifications";

export default ({
  search,
  notification,
  user: showUser,
}: {
  search: boolean;
  notification: boolean;
  user: boolean;
}) => {
  const { user, self, logout } = useAuth();
  const { setTerm } = useSearch();

  return (
    <header className="header d-flex align-items-center px-10 px-md-20 px-xxl-40">
      <div className="row align-items-center w-100 g-2">
        {search && (
          <div className="col">
            <div className="header__search d-flex me-10 me-md-0">
              <img src={searchIcon} />
              <Form.Control
                placeholder="جستجو .."
                style={{ background: "transparent" }}
                onChange={(e) => setTerm(e.target.value)}
              />
            </div>
          </div>
        )}
        {showUser && (
          <>
            {user && (
              <div className="col-auto">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="transparent"
                    id="dropdown-basic"
                    className="d-flex align-items-center py-half px-10 border-0"
                  >
                    <div className="header__avatar ml-10 ps-10">
                      <img
                        src={self.profile_image_url}
                        className="header__avatar__image rounded-circle"
                      />
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => logout()}>خروج</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </>
        )}
        {notification && (
          <div className="col-auto">
            <Notifications />
          </div>
        )}
      </div>
    </header>
  );
};
