import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Switch, Route, HashRouter } from "react-router-dom";
import { AuthProvider } from "./hooks/useAuth";
import { FeedProvider } from "./hooks/useFeed";
import { SearchProvider } from "./hooks/useSearch";
import { ConfigProvider } from "antd";

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider direction="rtl">
      <SearchProvider>
        <FeedProvider>
          <AuthProvider>
            <BrowserRouter>
              <Switch>
                <Route path="/" component={App} />
              </Switch>
            </BrowserRouter>
          </AuthProvider>
        </FeedProvider>
      </SearchProvider>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
