import React, { useContext, useEffect, useState } from "react";

export const SearchContext = React.createContext<any>("");

export const SearchProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const feed = useProvideSearch();

  return (
    <SearchContext.Provider value={feed}>{children}</SearchContext.Provider>
  );
};
export const useSearch = (): any => useContext(SearchContext);

const useProvideSearch = () => {
  const [term, setTerm] = useState("");

  return {
    term,
    setTerm,
  };
};
