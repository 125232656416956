import { get, remove, post, put } from "../config/api";

export const Streamers = {
  getAll: ({
    page,
    limit,
    cycle,
  }: {
    page: number;
    limit: number;
    cycle?: string;
  }) => get(`v2/twitch/streamers?page=${page}&limit=${limit}`),
  getFollowing: () => get("v2/twitch/streamers/following"),
  addOne: (username: string) =>
    post("v2/twitch/streamers", { streamer: username }),
};
