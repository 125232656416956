const errorDefs: { [key: string]: string } = {
  STREAMER_NOT_LIVE: "استریمر مورد نظر آفلاین می‌باشد.",
  INVALID_STREAMER_NAME: "استریمر در توییچ وجود ندارد.",
  STREAMER_ALREADY_EXISTS: "استریمر قبلا در توییچ‌فا اضافه شده است.",
  TITLE_NOT_VALID:
    "استریمر موظف است عنوان Persian یا Farsi یا Twitchfa را در تایتل استریم خود ذکر کند.",
};

export default (error: string) => {
  return errorDefs[error] || "مشکلی پیش آمد";
};
