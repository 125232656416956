import React, { useEffect, useState } from "react";
import { Streamers } from "../services/Streamers";
import LiveFeedCard from "./LiveFeedCard";
import { StreamerInterface } from "../models/StreamerInterface";
import "./LiveFeed.scss";
import { useAuth } from "../hooks/useAuth";
import { useFeed } from "../hooks/useFeed";
import { useSearch } from "../hooks/useSearch";
import LiveFeedSkeleton from "./LiveFeedSkeleton";

export default ({ selectedMode }: { selectedMode: string }) => {
  const { following } = useAuth();
  const { feed } = useFeed();
  const { term } = useSearch();

  return (
    <div className="live-feed" style={{ direction: "ltr" }}>
      <div className="row g-5 live-feed__row">
        {!!!feed.length && [...new Array(12)].map(() => <LiveFeedSkeleton />)}
        {feed &&
          feed
            .filter(({ twitchId: id1 }: { twitchId: string }) =>
              selectedMode === "discover"
                ? !following.some(
                    ({ twitchId: id2 }: { twitchId: string }) => id2 === id1
                  )
                : id1
            )
            .filter(({ displayName }: { displayName: string }) =>
              term ? displayName.toLowerCase().includes(term) : displayName
            )
            .map(
              ({
                displayName,
                gameName,
                profileUrl,
                thumbnailUrl,
                title,
                viewers,
              }: StreamerInterface) => (
                <div className="col-12 col-sm-6 col-lg-4 col-xxl-3" key={title}>
                  <LiveFeedCard
                    {...{
                      displayName,
                      gameName,
                      profileUrl,
                      thumbnailUrl,
                      title,
                      viewers,
                    }}
                  />
                </div>
              )
            )}
      </div>
    </div>
  );
};
