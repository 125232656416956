import notificationIcon from "../images/bell.svg";
import React, { useEffect, useState } from "react";
import { Notification } from "../services/Notification";
import { handleException } from "../helpers/handleException";
import { Badge, Dropdown } from "react-bootstrap";
import "./Notifications.scss";

export default () => {
  const [notifications, setNotifications] = useState<
    { createdAt: Date; id: string; title: string; message: string }[]
  >([]);
  const [readNotifications, setReadNotifications] = useState<string[]>([]);
  const [notificationCount, setNotificationCount] = useState<number>(0);

  useEffect(() => {
    (async () => {
      try {
        const { data: response } = await Notification.getAll();
        if (!response) {
          return;
        }
        setNotifications(response?.data);
      } catch (e) {
        handleException(null, e);
      }
    })();
  }, []);

  useEffect(() => {
    countNotifications();
  }, [notifications]);

  const countNotifications = () => {
    const lsNotifications: string | null = localStorage.getItem(
      "readNotifications"
    );

    const readNotifications = JSON.parse(lsNotifications || "[]");
    setReadNotifications(readNotifications);
    if (!notifications) return;
    const notificationIds = notifications
      .map(({ id }) => (readNotifications.includes(id) ? undefined : id))
      .filter(Boolean);
    setNotificationCount(notificationIds.length || 0);
  };

  const handleNotificationRegistry = () => {
    const notificationIds = notifications.map(({ id }) => id);
    localStorage.setItem("readNotifications", JSON.stringify(notificationIds));
    setNotificationCount(0);
    setTimeout(() => countNotifications(), 2500);
  };

  return (
    <Dropdown
      className="header__notification me-10"
      onToggle={handleNotificationRegistry}
    >
      <Dropdown.Toggle
        variant="transparent"
        id="dropdown-basic"
        className="d-flex align-items-center py-half px-10 border-0"
      >
        <div className="header__notification__icon">
          <img src={notificationIcon} height="20" />
          {notifications && notifications.length > 0 && (
            <>
              {!!notificationCount && (
                <div className="header__notification__badge">
                  {notificationCount}
                </div>
              )}
            </>
          )}
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {notifications &&
          notifications.length > 0 &&
          notifications
            .sort((a, b) => {
              if (a.createdAt > b.createdAt) {
                return -1;
              } else if (a.createdAt < b.createdAt) {
                return 1;
              } else {
                return 1;
              }
            })
            .map(({ id, title, message }) => (
              <Dropdown.Item key={id}>
                <div
                  style={{ width: 250, whiteSpace: "normal" }}
                  className="px-15 pt-half header__notification__item"
                >
                  <div className="row">
                    <div className="col-auto px-0">
                      <div className="header__notification__item__indicator">
                        {typeof readNotifications !== "undefined" &&
                        readNotifications.includes(id) ? (
                          <></>
                        ) : (
                          <i className="rounded-circle d-inline-flex ms-10" />
                        )}
                      </div>
                    </div>
                    <div className="col px-0">
                      <h6
                        className="header__notification__item__title"
                        style={{ fontSize: 15 }}
                      >
                        {title}
                      </h6>
                      <p className="header__notification__item__message mb-1 text-muted">
                        {message}
                      </p>
                    </div>
                  </div>
                </div>
              </Dropdown.Item>
            ))}
        {notifications && notifications.length === 0 && (
          <Dropdown.Item>
            <div
              style={{ width: 250, whiteSpace: "normal" }}
              className="px-15 pt-half header__notification__item"
            >
              <div className="row">
                <div className="col-auto px-0">
                  <div className="header__notification__item__indicator"></div>
                </div>
                <div className="col px-0">
                  <h6
                    className="header__notification__item__title"
                    style={{ fontSize: 15 }}
                  >
                    نوتیفیکیشن ها
                  </h6>
                  <p className="header__notification__item__message mb-1 text-muted">
                    نوتیفیکیشنی وجود ندارد.
                  </p>
                </div>
              </div>
            </div>
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};
