import { AxiosResponse } from "axios";

const axios = require("axios");
const token = () => localStorage.getItem("token");

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 120 * 1000,
  headers: {
    Authorization: `Bearer ${token()}`,
  },
});

instance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: any) => {
    return Promise.reject(error);
  }
);

const { get, post, put, delete: remove } = instance;

export { get, post, put, remove };
