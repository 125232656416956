import React, { useEffect, useState } from "react";
import logo from "../images/logo.svg";
import "./Navigation.scss";
import { Button, Nav } from "react-bootstrap";
import { NavLink, useLocation, useRouteMatch } from "react-router-dom";
import StreamerSnippet from "../components/StreamerSnippet";
import { Link } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import twitchIcon from "../images/twitch-icon.svg";
import { Login } from "../services/Login";
import { User } from "../services/User";
import handleApiError from "../helpers/handleApiError";
import LoginWithTwitch from "../components/LoginWithTwitch";

export default () => {
  const { user, logout, setFollowing } = useAuth();
  const [followList, setFollowList] = useState([]);
  const [maxItems, setMaxItems] = useState(6);
  const [refreshFollowListId, setRefreshFollowListId] = useState<any>();
  let { path } = useRouteMatch();
  const { pathname } = useLocation();

  const getFollowingList = async () => {
    const { data: response } = await User.getFollowing(user);
    const { data } = response;
    if (!data) return;
    setFollowList(data);
    return data;
  };

  useEffect(() => {
    (async () => {
      try {
        if (!user) return;
        const data = await getFollowingList();
        setFollowing(data);
        const refreshFollowList = setTimeout(() => getFollowingList(), 30000);
        setRefreshFollowListId(refreshFollowList);
      } catch (e) {
        handleApiError(e, logout);
      }
    })();

    return () => clearTimeout(refreshFollowListId);
  }, [user]);

  const handleBodyLock = () => {
    document.body.classList.add("locked");
  };

  const handleBodyUnlock = () => {
    document.body.classList.remove("locked");
  };

  return (
    <div
      className="sidebar d-none d-sm-block p-30"
      onMouseEnter={handleBodyLock}
      onMouseLeave={handleBodyUnlock}
    >
      <div className="sidebar__logo mb-40">
        <Link to="/home">
          <img src={logo} />
        </Link>
      </div>
      <div className="sidebar__menu ">
        <span className="sidebar__category d-inline-block text-secondary mb-10">
          گیمینگ
        </span>
        <Nav
          defaultActiveKey={pathname === "/add" ? "add" : "home"}
          className="flex-column"
        >
          <Nav.Link as={NavLink} eventKey="home" to="/home">
            کانال های توییج
          </Nav.Link>
          <Nav.Link as={NavLink} eventKey="add-streamer" to="/add">
            افزودن استریمر
          </Nav.Link>
        </Nav>
      </div>
      <hr
        style={{ backgroundColor: "#3B3A3F", opacity: 1 }}
        className="mb-20"
      />
      {!user && (
        <>
          <span className="sidebar__category d-inline-block text-secondary mb-10">
            وارد شوید
          </span>
          <span className="sidebar__category text-white d-flex mb-15">
            برای استفاده از تمامی امکانات توییچ‌فا وارد شوید.
          </span>
          <LoginWithTwitch />
        </>
      )}
      {user && (
        <>
          {!!followList.length && (
            <>
              <span className="sidebar__category d-inline-block text-secondary mb-20">
                دنبال می‌کنید
              </span>
              {followList
                .slice(
                  0,
                  maxItems < followList.length ? maxItems : followList.length
                )
                .map(
                  ({ displayName, gameName, viewers, profileUrl }, index) => (
                    <StreamerSnippet
                      key={displayName}
                      username={displayName}
                      category={gameName}
                      viewcount={viewers}
                      avatar={profileUrl}
                      last={followList.length === index + 1}
                      tiny
                    />
                  )
                )}
              {maxItems < followList.length && (
                <a
                  className="sidebar__show-more text-decoration-none"
                  onClick={() =>
                    setMaxItems((prevState) => prevState + maxItems)
                  }
                >
                  نمایش بیشتر
                </a>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
