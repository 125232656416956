import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import hero from "../images/add-streamer.svg";
import { useHistory } from "react-router-dom";
import { handleException } from "../helpers/handleException";
import { Streamers } from "../services/Streamers";
import { notification } from "../helpers/notification";
import errorParser from "../helpers/errorParser";
import { get } from "../config/api";
import toPersianNum from "../helpers/toPersianNum";

export default () => {
  const [streamer, setStreamer] = useState("");
  const [totalStreamers, setTotalStreamers] = useState<number>();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const { data: response } = await get("v2/twitch/stats");
        if (!response) return;
        const { data } = response;
        if (!data) return;
        const { totalStreamers } = data;
        if (!totalStreamers) return;
        setTotalStreamers(totalStreamers);
      } catch (e) {
        handleException(null, e);
      }
    })();
  }, []);

  const handleAddStreamer = async () => {
    try {
      if (!streamer || streamer.length < 3) return;
      const { data: response } = await Streamers.addOne(streamer);
      if (!response) return;
      const { data } = response;
      if (data) {
        return notification("success", "", "با موفقیت اضافه شد.");
      }
    } catch (e) {
      handleException(null, e);
    }
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={true}
      backdrop={true}
      className="modal text-center"
      onHide={() => history.replace("")}
    >
      <Modal.Body className="py-60 px-50">
        <img src={hero} alt="افزودن استریمر" />
        <h4>افزودن استریمر جدید</h4>
        <div className="modal-form mt-30">
          <Form.Control
            value={streamer}
            onChange={(e) => setStreamer(e.target.value)}
            placeholder="نام استریمر.."
          />
          <Button
            type="primary"
            className="w-100 mt-10"
            disabled={!streamer || streamer.length < 3}
            onClick={handleAddStreamer}
          >
            افزودن استریمر
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center py-25 px-30">
        هم اکنون به جمع
        <span style={{ color: "#717DFE" }}>
          {totalStreamers && toPersianNum(totalStreamers)}
        </span>
        استریمر ثبت شده در توییچ‌فا بپیوندید.
      </Modal.Footer>
    </Modal>
  );
};
