import React from 'react'
import logo from './logo.svg';
import Navigation from "./Navigation";
import {Nav} from "react-bootstrap";

export default ({children} : {children: JSX.Element | JSX.Element[] | string}) => {
    return (
            <>
                <Navigation />
                <main className="app-container">
                        {children}
                </main>
            </>
    )
}