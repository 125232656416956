import { get, remove, post, put } from "../config/api";

export const User = {
  getSelf: (token: string) =>
    get(`v2/twitch/self`, { headers: { Authorization: `Bearer ${token}` } }),
  getFollowing: (token: string) =>
    get(`v2/twitch/following`, {
      headers: { Authorization: `Bearer ${token}` },
    }),
};
