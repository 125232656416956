import React from "react";
import "./StreamerSnippet.scss";

function convert(num: number): any {
  return Math.abs(num) > 999
    ? (Math.sign(num) * Math.round(Math.abs(num) / 100)) / 10 + "k"
    : Math.sign(num) * Math.abs(num);
}

type StreamerSnippetInterface = {
  username: string;
  category: string;
  viewcount: any;
  avatar: string;
  tiny?: boolean;
  last?: boolean;
  direction?: "rtl" | "ltr";
  title?: string;
};

export default ({
  username,
  category,
  viewcount,
  avatar,
  tiny,
  last,
  direction,
  title,
}: StreamerSnippetInterface) => {
  return (
    <a
      href={`https://twitch.tv/${username}`}
      target="_blank"
      className="text-decoration-none"
    >
      <div
        className={`streamer-snippet${last ? "" : tiny ? " mb-15" : ""}${
          direction === "ltr" ? " ltr" : ""
        }`}
      >
        <div className="row g-3 align-items-center">
          {!tiny && (
            <div className="col-12 text-white">
              <div className="streamer-snippet__title">{title}</div>
            </div>
          )}
          <div className="col-auto">
            <div className="streamer-snippet__avatar">
              <img src={avatar} className="rounded-circle" alt={username} />
            </div>
          </div>
          <div className="col">
            <div className={`streamer-snippet__username${tiny ? " tiny" : ""}`}>
              {username}
            </div>
            <div className={`streamer-snippet__category${tiny ? " tiny" : ""}`}>
              {category}
            </div>
          </div>
          <div className="col-auto">
            <div className="streamer-snippet__view-count d-flex align-items-center">
              <i className="streamer-snippet__view-indicator rounded-circle d-inline-flex mx-1" />
              {convert(viewcount)}
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};
