import { notification as antdNotification } from "antd";

export const notification = (
  type: string,
  title: string,
  message?: string
): any =>
  // @ts-ignore
  antdNotification[type]({
    message: title,
    description: message,
  });
