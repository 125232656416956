import React from "react";
import "./LiveFeedSkeleton.scss";

export default () => {
  return (
    <div className="col-md-6 col-lg-4 col-xxl-3">
      <div className="skeleton__card">
        <div className="skeleton__thumbnail skeleton__wave"></div>
        <div className="skeleton__snippet px-20 pt-20 pb-20">
          <div className="skeleton__title skeleton__wave" />
          <div className="skeleton__avatar skeleton__wave rounded-circle mt-15" />
        </div>
      </div>
    </div>
  );
};
