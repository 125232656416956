import React, { useEffect, useState } from "react";
import "antd/lib/notification/style/index.css";
import Main from "./layout/Main";
import Header from "./layout/Header";
import Banner from "./components/Banner";
import { Container } from "react-bootstrap";
import bannerImage from "./images/banner.jpg";
import "./bootstrap.scss";
import "./app.scss";
import LiveFeed from "./components/LiveFeed";
import useTitle from "./hooks/useTitle";
import {
  useLocation,
  useHistory,
  Route,
  useRouteMatch,
  Switch,
} from "react-router-dom";
import { handleException } from "./helpers/handleException";
import { useAuth } from "./hooks/useAuth";
import { Scrollbars } from "rc-scrollbars";
import AddModal from "./components/AddModal";
import LoginModal from "./components/LoginModal";

function App(props: any) {
  let query = new URLSearchParams(useLocation().search);
  const title = useTitle("Twitchfa");
  const { login, user } = useAuth();
  const [selectedMode, setSelectedMode] = useState("all");
  const history = useHistory();
  let { path } = useRouteMatch();

  useEffect(() => {
    (async () => {
      try {
        const token = query.get("token");
        if (!token) return;
        await login(token);
        history.push("/");
      } catch (e) {
        handleException(null, e);
      }
    })();
  }, []);

  return (
    <>
      <Scrollbars style={{ height: "100vh" }} autoHide>
        <Main>
          <Header search notification user />
          <Banner imageUrl={bannerImage} height={300} className="mb-30" />
          <div className="blur-effect px-20 px-lg-40 py-40">
            <Container className="px-20 px-md-30 px-lg-40">
              <div className="d-flex flex-column flex-xs-row align-items-center mb-40">
                <h2 className="mb-0">کانال های زنده</h2>
                <div className="data-switch d-flex px-half py-half me-15">
                  {[
                    { title: "همه", type: "all" },
                    { title: "دنبال نشده", type: "discover" },
                  ].map(({ title, type }) => (
                    <div
                      key={type}
                      onClick={() =>
                        type === "discover"
                          ? user
                            ? setSelectedMode(type)
                            : window.location.replace("/login")
                          : setSelectedMode(type)
                      }
                      className={`data-switch__item${
                        selectedMode === type ? " active" : ""
                      } px-10 py-half`}
                    >
                      {title}
                    </div>
                  ))}
                </div>
              </div>
              <LiveFeed selectedMode={selectedMode} />
            </Container>
          </div>
          <Route path={`/add`} component={AddModal} />
          <Route path={`/login`} component={LoginModal} />
        </Main>
      </Scrollbars>
    </>
  );
}

export default App;
