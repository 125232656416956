import React, { useContext, useEffect, useState } from "react";
import { Streamers } from "../services/Streamers";

export const LiveFeedContext = React.createContext<any>([]);

export const FeedProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const feed = useProvideFeed();

  return (
    <LiveFeedContext.Provider value={feed}>{children}</LiveFeedContext.Provider>
  );
};
export const useFeed = (): any => useContext(LiveFeedContext);

const useProvideFeed = () => {
  const [feed, setFeed] = useState<any[]>([]);

  const prepareFeed = async ({ page = 1, limit = 100, cycle = undefined }) => {
    const { data: response } = await Streamers.getAll({ page, limit, cycle });
    const { data, pagination, cycleId } = response;
    setFeed((prevState) => [...prevState, ...data]);
    if (!pagination) return;
    const { current, total, next } = pagination;
    if (current < total) {
      await prepareFeed({ page: next, cycle: cycleId });
    }
  };

  useEffect(() => {
    if (feed.length === 0) {
      prepareFeed({});
    }
  }, []);

  return {
    feed,
  };
};
